import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Gallery from "../components/gallery/gallery"
import Companys from "../components/companys"
import pakmaya from "../assets/img/pakmaya-digital-marketing.png"
import tcdd from "../assets/img/tcdd-digital-marketing.png"
import ecanta from "../assets/img/ecanta-digital-marketing.png"
import yolcu from "../assets/img/yolcu-360-digital-marketing.png"
import robesnmore from "../assets/img/design-pc-5.png"
import liva from "../assets/img/liva-pastacilik-case-study-ramadan.png"

import { Link } from "gatsby"

const Referances = () => (
  <Layout>
    <SEO
      title="Brands"
      description="Check out statistics from our previous clients. These reflect our services and special strategies."
    />
    <div className="refrances container">
      <div className="referances-head">
        <h1>Real success stories.</h1>
        <p>
          Check out statistics from our previous clients. These reflect our
          services and special strategies.
        </p>
      </div>

      <div className="row referances-markalar py60">
      <div className="item col-md-6">
        <Link to="/success-stories/liva-pastacilik-case-study/"> <img
            src={liva}
            alt="Liva Pastacılık"
          /></Link>
          <p className="small">Digital Marketing</p>
          <Link to="/success-stories/liva-pastacilik-case-study/">Liva Pastacılık</Link>
          <p>
          Liva Pastry, which has been serving in Ankara with its 4 branches since 1993, has achieved rising success over the years with its digital transformation. 
          </p>
          <div className="item-bottom">
            <p className="bold">7% </p>
            <p className="small">Increase in E-commerce Conversion Rate </p>
          </div>
          
        </div>
      <div className="item col-md-6">
        <Link to="/success-stories/robesnmore/"> <img
            src={robesnmore}
            alt="robesnmore"
          /></Link>
          <br/>
          <br/>
          <br/>
          <br/>
          <p className="small">Digital Marketing</p>
          <Link to="/success-stories/robesnmore/">robesnMore</Link>
          <p>
          robesNmore is a family-owned company located in California, specializing in importing, manufacturing, and wholesale towels, bathrobes, and other linens.
          </p>
          <div className="item-bottom">
            <p className="bold">+132%</p>
            <p className="small">Organic session growth.</p>
          </div>
          <div className="item-bottom">
            <p className="bold">+44%</p>
            <p className="small">New Users</p>
          </div>
        </div>
        <div className="item col-md-6">
        <Link to="/success-stories/pakmaya/"> <img
            src={pakmaya}
            alt="pakmaya instagram hesabı sosyal medya yönetimi"
          /></Link>
          <p className="small">Digital Marketing</p>
          <Link to="/success-stories/pakmaya/">Pakmaya</Link>
          <p>
            Roots of Pakmaya were laid in 1923 with the pharmaceutical
            laboratory established by Professor Mustafa Nevzat.
          </p>
          <div className="item-bottom">
            <p className="bold">10x</p>
            <p className="small">Organic traffic growth.</p>
          </div>
          <div className="item-bottom">
            <p className="bold">+%126</p>
            <p className="small">Increasing the audience reached.</p>
          </div>
        </div>
        <div className="item col-md-6">
        <Link to="/success-stories/tcdd-tasimacilik/">
          <img
            src={tcdd}
            alt="bir laptop ve telefon ekranında tcddtasimacilik.gov.tr sitesi açık"
          />
          </Link>
          <p className="small">Digital Marketing</p>
          <Link to="/success-stories/tcdd-tasimacilik/">
            TCDD Taşımacılık
          </Link>
          <p>
            Our collaboration with the Turkish State Railways (TCDD
            Transportation) started in 2017 with UI / UX and website
            infrastructure development.
          </p>
          <div className="item-bottom">
            <p className="bold">+%24</p>
            <p className="small">Conversion rate increase.</p>
          </div>
          <div className="item-bottom">
            <p className="bold">+%125</p>
            <p className="small">Organic traffic growth.</p>
          </div>
        </div>
        <div className="item col-md-6">
        <Link to="/success-stories/ecanta/"> <img
            src={ecanta}
            alt="apple marka bilgisayar ekranında eçanta.com.tr sitesi açık"
          /></Link>
          <p className="small">Digital Marketing</p>
          <Link to="/success-stories/ecanta/">eÇanta</Link>
          <p>
            ecanta.com.tr is a bag retailer that started operating in 2002. The
            company became a very successful e-commerce website that brings
            designer brands to users in Turkey.
          </p>
          <div className="item-bottom">
            <p className="bold">+28k</p>
            <p className="small">Organic traffic growth.</p>
          </div>
          <div className="item-bottom">
            <p className="bold">+%26</p>
            <p className="small">Reducing the cost of advertising.</p>
          </div>
        </div>

        <div className="item col-md-6">
        <Link to="/success-stories/yolcu360/"><img src={yolcu} /></Link>
          <p className="small">Digital Marketing</p>
          <Link to="/success-stories/yolcu360/">Yolcu 360</Link>
          <p>
            The brand that makes you think how easy it is to rent a car!
            Turkey's largest online car hire site Yolcu 360 started its journey
            in 2018 and we developed their Google ve Facebook Ads campaigns.
          </p>
          <div className="item-bottom">
            <p className="bold">+%24</p>
            <p className="small">Conversion rate.</p>
          </div>
          <div className="item-bottom">
            <p className="bold">-%12</p>
            <p className="small">Cost-per-click reduction.</p>
          </div>
        </div>
      </div>
    </div>

    <div className="our-clients container">
      <h2>Our Clients</h2>
      <Companys />
    </div>

    <div className="py60">
      <Gallery name="all" />
    </div>
  </Layout>
)

export default Referances
